<div class="knowledge-base">
  @if(displayHeader()) {
    <h2>{{ header() }}</h2>
  }
  <ul style="display: none">
    @for(item of items || []; track item) {
    <li><a [routerLink]="[baseLink(), item.slug]">{{item.title}}</a></li>
    }
  </ul>

  @defer (when items){
    <owl-carousel-o [options]="customOptions">
      @for(item of items; track item; let i = $index){
        <ng-template carouselSlide [width]="itemSize">
          <div [id]="i" (click)="setActive(i)"
               [routerLink]="[baseLink(), item.slug]"
               class="card"
               [ngClass]="{ 'active': isActive(item.slug)}"
               [ngStyle]="{ 'background': color() }"
          >
            <div class="card-inner">
              <img class="icon" [src]="item.icon" alt="icon">
              <h3>{{item.title}}</h3>
              <p>{{item.description}}</p>
            </div>
            <span class="learn-more">
              <a [routerLink]="[baseLink(), item.slug]">{{ moreLink() }}</a>
              <img src="/assets/images/arrow-learn.png" alt="arrow-learn">
            </span>
          </div>
        </ng-template>
      }
    </owl-carousel-o>
  }
</div>
