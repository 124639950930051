import { Observable } from 'rxjs';
import {CarouselItem} from "../common/carousel/carousel.component";

export enum FilterType {
  eq = '$eq',
  eqi = '$eqi',
  ne = '$ne',
  nei = '$nei',
  lt = '$lt',
  lte = '$lte',
  gt = '$gt',
  gte = '$gte',
  in = '$in',
  notIn = '$notIn',
  contains = '$contains',
  notContains = '$notContains',
  containsi = '$containsi',
  notContainsi = '$notContainsi',
  null = '$null',
  notNull = '$notNull',
  between = '$between',
  startsWith = '$startsWith',
  startsWithi = '$startsWithi',
  endsWith = '$endsWith',
  endsWithi = '$endsWithi',
  or = '$or',
  and = '$and',
  no = '$no'
}

export interface Filter {
  type: FilterType;
  field: string;
  value: string|Filter[];
}

// Root Interface
export interface InfoResponseBase<T> {
  data: T;
}

// Data Interface
export interface KnowledgeBaseItem extends EntityBase<KnowledgeBaseItemAttributes> {}
export interface CommonPageInfo extends EntityBase<CommonPageAttributes> {}
export interface MainPageInfo extends EntityBase<MainPageAttributes> {}
export interface AboutUsInfo extends EntityBase<AboutUsAttributes> {}

// Attributes Interface
export interface KnowledgeBaseItemAttributes {
  title: string;
  slug: string;
  iconUrl: string;
  shortDescription: string;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  publishedAt: string; // ISO date string
  description: ListItemChild[];
  questions: Question[];
}



// Attributes Interface
export interface CommonPageAttributes {
  title: string;
  tag: string;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  publishedAt: string; // ISO date string
  blocks: DynamicPageBlock[];
}

export interface EntityBase<T> {
  id: number;
  attributes: T;
}

export interface EntityChildBase<T> {
  data: T[];
}

export interface MainPageAttributes {
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  publishedAt: string; // ISO date string
  Body: MainPageBlock[];
}
export interface AboutUsAttributes {
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  publishedAt: string; // ISO date string
  title: string;
  subTitle: ListItemChild[];
  description: ListItemChild[];
  body: AboutUsBlockComponent[];
}

export interface BlockWithHeaderComponent {
  id: number;
  __component: 'components.header';
  body: ListItemChild[];
  title: string;
  desclaimer?: string;
}

export interface DefinitionsBlockComponent {
  id: number;
  __component: 'components.definitions-block';
  text: ListItemChild[];
  title: string;
  rows: DefinitionsBlockRowItem[];
}

export interface CarouselComponent {
  id: number;
  __component: 'components.carousel';
  baseLink: string;
  title: string;
  entityName: string;
  moreLink: string;
  itemMap: CarouselItem;
}

export interface TeamsComponent {
  id: number;
  __component: 'landings.team';
  title: string;
  teams: EntityChildBase<TeamItem>;
}

export interface ContactUsComponent {
  id: number;
  __component: 'landings.contact-us';
  title: string;
  text: string;
  contactName: string;
  contactUrl: string;
}

export interface PaymentsBlockComponent {
  id: number;
  __component: 'landings.payment-block';
  text: ListItemChild[];
  subTitle: string;
}

export interface ProfitabilityBlockComponent {
  id: number;
  __component: 'landings.profitability';
  title: string;
  percentage: string;
  percentageDescription: string;
  description: ListItemChild[];
  imageUrl: string;
  detailsName: string;
  detailsUrl: string;
}

export interface PricesBlockComponent {
  id: number;
  __component: 'landings.prices';
  title: string;
  profitPercentage: string;
  profitDescription: string;
  detailsUrl: string;
  profitList: string[];
  plusList: string[];
}

export interface AboutUsBlockComponent {
  id: number;
  desclaimer: string;
  iconUrl: string;
  title: string;
  body: ListItemChild[];
}

export interface ApiBlockComponent {
  id: number;
  __component: 'landings.api-block';
  description: string;
  iconUrl: string;
}

export interface ApiSectionBlockComponent {
  id: number;
  __component: 'landings.api-section';
  title: string;
  subTitle: string;
  aboutName: string;
  aboutUrl: string;
  exchanges: EntityChildBase<ExchangeItem>;
}

export interface StartBlockComponent {
  id: number;
  __component: 'landings.start-block';
  title: string;
  subTitle: string;
  startButton: string;
  startLink: string;
  backgroundUrl: string;
}

export interface DefinitionsBlockRowItem {
  id: number;
  title: string;
  body: ListItemChild[];
}

export interface TeamItem extends EntityBase<TeamAttributes> {}
export interface ExchangeItem extends EntityBase<ExchangeAttributes> {}
export interface GlossaryItem extends EntityBase<GlossaryItemAttributes> {}
export interface GlossaryPage extends EntityBase<GlossaryPageAttributes> {}

// Attributes Interface
export interface GlossaryItemAttributes {
  name: string;
  letter: string;
  iconUrl: string;
  shortDescription: string;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  publishedAt: string; // ISO date string
  body: ListItemChild[];
}

export interface TeamAttributes {
  name: string;
  slug: string;
  title: string;
  link: string;
  description: string;
  order: number;
  photoUrl: string;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  publishedAt: string; // ISO date string
}

export interface ExchangeAttributes {
  name: string;
  slug: string;
  mainIcon: string;
  alternativeIcon: string;
  link: string;
  order: number;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  publishedAt: string; // ISO date string
}

export interface GlossaryPageAttributes {
  title: string;
  letters: string[];
  search: string;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  publishedAt: string; // ISO date string
}

// Description Interface
export interface TextBlock {
  type: 'paragraph';
  children: TextBlockItem[];
}

// DescriptionChild Interface
export interface TextBlockItem {
  type: 'text';
  text: string;
  italic?: boolean; // Optional property
  bold?: boolean; // Optional property
}

// Question Interface
export interface Question {
  id: number;
  name: ListItemChild[];
  tag: string;
  answers: Answer[];
}

// Answer Interface
export interface Answer {
  id: number;
  header: string;
  body: ListItemChild[];
}

// Paragraph Interface
export interface Paragraph {
  type: 'paragraph';
  children: BodyChild[];
}

// BodyChild Type (Union of Text and Link)
export type BodyChild = TextBlock | LinkChild;

// LinkChild Interface
export interface LinkChild {
  type: 'link';
  url: string;
  children: TextBlockItem[];
}

// LinkChild Interface
export interface HeaderItem {
  type: 'heading';
  level: number;
  children: TextBlockItem[];
}

// List Interface
export interface List {
  type: 'list';
  format: 'unordered' | 'ordered';
  indentLevel?: number; // Optional property
  children: ListItem[];
}

// ListItem Interface
export interface ListItem {
  type: 'list-item';
  children: ListItemChild[];
}

// ListItemChild Type (Union of Text, Link, and Nested List)
export type ListItemChild = TextBlockItem | LinkChild | List | Paragraph | HeaderItem;
export type DynamicPageBlock = BlockWithHeaderComponent | DefinitionsBlockComponent;
export type MainPageBlock = StartBlockComponent | PaymentsBlockComponent | ApiBlockComponent | ApiSectionBlockComponent | ContactUsComponent | PricesBlockComponent | TeamsComponent | CarouselComponent | ProfitabilityBlockComponent;


export abstract class InfoData {
    abstract getDataCollection<T>(entity: string, includes: string[], filters: Filter[], sortFields: string[]|null, limit: number|null, page: number|null): Observable<InfoResponseBase<T>>;
}
