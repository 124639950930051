import {Component, OnInit} from '@angular/core';
import {commonImports} from "../app.imports";
import {AboutUsInfo, InfoData} from "../abstract/info";

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [...commonImports],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent implements OnInit {
  infoData?: AboutUsInfo;

  constructor(private informationService: InfoData) {
  }

  ngOnInit(): void {
    this.informationService.getDataCollection<AboutUsInfo>('about-us', ['[body][populate]=*'], [], null, null, null).subscribe(res => {
      this.infoData = res.data;
    });
  }
}
