@if (infoData) {
  <div class="about-us">
    <div class="about-us-inner">
      <div class="header">
        <h1 class="gradient-text">{{infoData.attributes.title}}</h1>
        <div class="background-shadow"></div>
        <div class="background-shadow-mob"></div>
        <ngx-text-block [blocks]="infoData.attributes.subTitle"></ngx-text-block>
      </div>

      <div class="body">
        <ngx-text-block [blocks]="infoData.attributes.description" [className]="'par-text-big'"></ngx-text-block>

        <div class="body-inner">
          @for (item of infoData.attributes.body; track item.id; let isEven = $even; let isLast = $last) {
            <div class="block" [class.reverse]="!isEven" [class.new]="isLast">
              <div class="block-inner">
                <h2 class="gradient-text">{{item.title}}</h2>
                <ngx-text-block [blocks]="item.body"></ngx-text-block>
                @if(item.desclaimer) {
                  <span><p>{{item.desclaimer}}</p></span>
                }
              </div>
              <div class="block-img-inner">
                <img [src]="item.iconUrl" [alt]="item.title">
              </div>
            </div>

            @if(!isLast) {
              <div class="line"></div>
            }
          }
        </div>
      </div>
    </div>
  </div>
}
