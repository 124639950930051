import {AfterViewInit, Component, inject} from '@angular/core';
import {commonImports} from "../app.imports";
import {UserAccessService} from "../services/auth.service";

@Component({
  selector: 'ngx-not-found',
  styleUrls: ['./not-found.component.scss'],
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [...commonImports]
})
export class NotFoundComponent  implements AfterViewInit {
  private readonly accessService = inject(UserAccessService);
  isAuthenticated: boolean = false;

  ngAfterViewInit(): void {
    this.accessService
      .getUser()
      .subscribe((user) => {
        this.isAuthenticated = !!user;
      });
  }
}
