import {Component, model, OnInit} from '@angular/core';
import {ActivatedRoute, RouterOutlet} from '@angular/router';
import {SeoService} from "./@core/utils/seo.service";
import {Meta} from "@angular/platform-browser";
import {AnalyticsService} from "./@core/utils/analytics.service";
import {HeaderComponent} from "./common/header/header.component";
import {FooterComponent} from "./common/footer/footer.component";
import {LoadingService} from "./services/loading.service";
import { LoaderComponent } from "./common/loader/loader.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Burvix Traders';
  isLoading = model(false);

  constructor(
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private meta: Meta,
    private seoService: SeoService,
    private loadingService: LoadingService
  ) {
    this.meta.addTags([
      {name: 'description', content: 'We are an algo trading platform providing tailored trading solutions. Designed to make idle funds work efficiently on the spot markets only. Automate your trading and use all market opportunities to generate your profit.'},
      {name: 'author', content: 'Manimama OU'},
      {name: 'keywords', content: 'Trading, TradingBot, CryptoTrading, Investment, Profit'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.route.queryParamMap.subscribe(params => {
      const key = params.keys.filter(x => x.toLowerCase() === 'refcode');
      if (key[0]) {
        localStorage.setItem('refCode', params.get(key[0])!);
      }
    });

    this.loadingService.observableLoadState.subscribe(res => {
      this.isLoading.update(_ => res);
    })
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
    this.seoService.trackCanonicalChanges();
  }
}
