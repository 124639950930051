import { Observable } from 'rxjs';

export interface User {
  id: number;
  email: string;
  name: string;
  photoUrl: string;
  isRegistered: boolean;
  telegramAccount: string;
  roles: string[];
  registeredOn: Date;
  phone: string;
  whatsApp: string;
  receiveMarketingInfo: boolean;
  userCode: string;
  referral: string;
  lastOrderDate: Date;
  bots: number;
}

export interface ProgressInfo {
  title: string;
  value: number;
  activeProgress: number;
  description: string;
  type: string;
}

export interface Contacts {
  user: User;
  type: string;
}

export interface RecentUsers extends Contacts {
  time: number;
}

export interface BotStatistic {
  amount: number;
  period: Date;
}

export interface FullBotStatistic {
  botId: string;
  allAmounts: BotStatistic[];
  lastMonth: BotStatistic[];
  lastInvoice: BotStatistic[];
  allCommissions: BotStatistic[];
  lastInvoiceCommission: BotStatistic[];
  statInfo: ProgressInfo[];
}

export interface InvoiceDates {
  lastInvoiceDate: Date;
  availableDates: PreCalculatedInvoice[];
}

export interface PreCalculatedInvoice {
  orderId: number;
  date: Date;
  orderPnl: number;
  totalAmount: number;
  ordersCount: number;
  marketOrder: string;
}

export interface BotInfo {
    name: string;
    exchange: string;
    startDate: Date;
    lastInvoiceDate: Date;
    newProfit: number;
    inProgressPnl: number;
    openOrders: number;
    totalPnl: number;
}

export interface BotOrder {
  id: number;
  totalAmount: number;
  itemPrice: number;
  origQty: number;
  type: string;
  side: string;
  symbol: string;
  baseCoin: string;
  cryptoCoin: string;
  lastModifiedDate: Date;
}

export interface Paging<TItem> {
  items: TItem[];
  totalCount: number;
}

export interface AttentionInfo {
  unreadNotifications: number;
  isInvoiceExpired: boolean;
  unpaidInvoiceId: number;
  invoices: PaymentInfo[];
}

export interface Notification {
  title: string;
  message: string;
  id: number;
  type: string;
  createdOn: Date;
  isRead: boolean;
  entityId: string;
}

export interface Invoice {
  id: number;
  status: string;
  totalAmount: number;
  amountToPay: number;
  amountToPayDescription: string;
  from: Date;
  to: Date;
  dueDate: Date;
  isExpired: boolean;
  externalPayUrl: string;
}

export interface BotRunInfo {
  id: number;
  startDate: Date;
  endDate: Date;
  profit: number;
  isInBuyMode: boolean;
  excludedOrders: BotOrder[];
}

export interface PairComment {
  baseCoin: string;
  cryptoCoin: string;
  comment: string;
}

export interface AssetBalance {
  coin: string;
  balance: number;
  locked: number;
  price: number;
}

export interface PaymentInfo {
  invoiceId: number;
  createdOn: Date;
  dueDate: Date;
  begin: Date;
  end: Date;
  totalProfit: number;
  invoiceAmount: number;
  exchangeName: string;
  baseCoin: string;
  cryptoCoin: string;
  isPaid: boolean;
  paidOn: Date;
  paymentLink: string;
}

export interface BotShortInfo {
  botId: string;
  baseCoin: string;
  isInBuyMode: boolean;
  cryptoCoin: string;
  exchangeSettingId: number;
  exchangeType: string;
  exchangeName: string;
  unrealizedPnl: number;
  currentAction: string;
  startAmount: number;
  startedOn: Date;
}

export interface BotPairLookup {
  name: string;
  exchangeId: number;
  pairs: {botId: string, baseCoin: string|null, cryptoCoin: string}[];
}

export interface ReferralInfo {
  referralId: string;
  rewardPercent: number;
  receivedReward: number;
  totalReward: number;
  startedCountClients: number;
  clients: ReferralInfoClient[];
}

export interface ReferralInfoClient {
  userId: number;
  registeredDate: Date;
  earned: number;
}

export enum BotAction {
  Running,
  Processing,
  StopMarket,
  StopTakeProfit,
  StopZeroProfit,
  Stopped
}

export interface BotApiSettingInfo {
  amount: number;
  botCurrency: number;
  settingId: number;
}

export abstract class UserData {
  abstract clearCache(): void;
  abstract getMyUser(): Observable<User>;
  abstract updateUserProfile(account: any): Observable<any>;
  abstract getUsers(): Observable<User[]>;
  abstract getContacts(): Observable<Contacts[]>;
  abstract getRecentUsers(): Observable<RecentUsers[]>;
  abstract getMyProfit(): Observable<FullBotStatistic>;
  abstract getBotProfit(botId: string): Observable<FullBotStatistic>;
  abstract getBotProfitForDateRange(start: string, end: string): Observable<{[key: string]: BotStatistic[]}>;
  abstract getInvoices(userId: any): Observable<InvoiceDates>;
  abstract getBots(): Observable<BotInfo[]>;
  abstract getBotOrders(botId: string, take: number, skip: number): Observable<Paging<BotOrder>>;
  abstract getNotifications(take: number, skip: number): Observable<Paging<Notification>>;
  abstract getNotificationsByType(type: string, take: number, skip: number): Observable<Paging<Notification>>;
  abstract getAttentionInfo(): Observable<AttentionInfo>;
  abstract getInvoice(id: number): Observable<Invoice>;
  abstract getPairComments(): Observable<{[key: number]: PairComment[]}>;
  abstract getExchangeBalance(id: number): Observable<AssetBalance[]>;
  abstract getPayments(take: number, skip: number, exchangeId: number, botId: string): Observable<Paging<PaymentInfo>>;
  abstract getShortBots(resetCache: boolean): Observable<BotShortInfo[]>;
  abstract getBotShortInfo(addDefaultValues: boolean): Observable<BotPairLookup[]>;
  abstract getOrders(take: number, skip: number, botId: string, exchangeSettingId: number, isWithoutInvoice:boolean, startDate: string, endDate: string): Observable<Paging<BotOrder>>;
  abstract getOpenOrders(botId: string): Observable<BotOrder[]>;
  abstract getReferralInfo(): Observable<ReferralInfo>;
  abstract getOrdersFile(botId: string, exchangeSettingId: number, isWithoutInvoice: boolean, startDate: string, endDate: string): Observable<Blob>;
  abstract sendBotAction(botId: string, action: BotAction): Observable<any>;
  abstract getBotLaunchSettings(invoiceId: number): Observable<BotApiSettingInfo>;
  abstract launchNewBot(settingId: number, amount: number, botCurrencyId: number) : Observable<any>;
  abstract getBalanceSnapshot(settingId: number, year:number|null) : Observable<BotStatistic[]>;

  abstract getUsersOrdersFile(email: string, botId: string, exchangeSettingId: number, isWithoutInvoice: boolean, startDate: string, endDate: string): Observable<Blob>;
  abstract deleteHistoryOrdersById(id: number): Observable<any>;
  abstract getNotificationById(id: number): Observable<Notification>;
  abstract getUserProfit(userId: string): Observable<FullBotStatistic>;
  abstract getUserBots(userId: string): Observable<BotInfo[]>;
  abstract getUserBotOrders(userId: string, botId: string, take: number, skip: number): Observable<Paging<BotOrder>>;
  abstract getUserBotProfit(userId: string, botId: string): Observable<FullBotStatistic>;
  abstract getUserBotProfitForDateRange(userId: string, start: string, end: string): Observable<{[key: string]: BotStatistic[]}>;
  abstract getUserNotifications(userId: string, take: number, skip: number): Observable<Paging<Notification>>;
  abstract getUserNotificationsByType(userId: string, type: string, take: number, skip: number): Observable<Paging<Notification>>;
  abstract getUserNotificationById(userId: string, id: number): Observable<Notification>;
  abstract getUserBotRuns(botId: string): Observable<{[key: string]: BotRunInfo[]}>;
  abstract createInvoice(userId: any, runs: number[], totalAmount: number, toPayAmount: number, toPayDescription: string, approveInvoice: boolean): Observable<any>;
  abstract setBotComment(userId: any, botId: any, comment: any): Observable<any>;
  abstract getUserPayments(userId: string, take: number, skip: number, exchangeId: number, botId: string): Observable<Paging<PaymentInfo>>;
  abstract getUserShortBots(userId: any, resetCache: boolean): Observable<BotShortInfo[]>;
  abstract getUserBotShortInfo(userId: any, addDefaultValues: boolean): Observable<BotPairLookup[]>;
  abstract getUserOrders(userId: any, take: number, skip: number, botId: string, exchangeSettingId: number, isWithoutInvoice:boolean, startDate: string, endDate: string): Observable<Paging<BotOrder>>;
  abstract getUserOpenOrders(userId: any, botId: string): Observable<BotOrder[]>;
  abstract getUserReferralInfo(userId: any): Observable<ReferralInfo>;
  abstract getUserPairComments(userId: any): Observable<{[key: number]: PairComment[]}>;
  abstract getUserExchangeBalance(userId: any, id: number): Observable<AssetBalance[]>;
  abstract completePayment(invoiceId: any): Observable<any>;
  abstract sendUserBotAction(userId: any, botId: string, action: BotAction): Observable<any>;
  abstract deleteUserBotRun(userId: any, botRunId: number): Observable<any>;
  abstract getUserBalanceSnapshot(userId: any, settingId: number, year:number|null) : Observable<BotStatistic[]>;
}
