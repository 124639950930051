import {Component, HostListener, input, OnChanges, OnInit, output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [
  ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent implements OnInit, OnChanges{
  dropdownOpen = false;

  options = input<(string | {key:string, value: string, color?: string})[]>([]);
  isPanel = input<boolean>();
  selected = output<string>();

  selectedOption: string | {key:string, value: string, color?: string} | null = null;

  ngOnInit(): void {
    if (this.options() && this.options().length > 0) {
      this.selectOption(this.options()[0]);
    }
  }

  getValue(val: string | {key:string, value: string, color?: string}) {
    return typeof val === "string" ? val : val.value;
  }

  getTitle(val: string | {key:string, value: string, color?: string}) {
    return typeof val === "string" ? val : val.key;
  }

  getColor(val: string | {key:string, value: string, color?: string}) {
    return typeof val !== "string" && val.color ? val.color : 'white';
  }

  // Если список опций изменяется динамически
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] && this.options().length > 0) {
      if(this.options()[0] == this.selectedOption) return;

      this.selectOption(this.options()[0]);
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOption(option: string | {key:string, value: string, color?: string}) {
    this.selectedOption = option;
    this.dropdownOpen = false;
    this.selected.emit(this.getValue(option));
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown')) {
      this.dropdownOpen = false;
    }
  }
}
