import {Component, input, output} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-stop-action-panel',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './stop-action-panel.component.html',
  styleUrl: './stop-action-panel.component.scss'
})
export class StopActionPanelComponent {

  title = input<string>();
  isGreat = input<boolean>();

  result = output<boolean>();

  sendResult(res: boolean){
    this.result.emit(res);
  }
}
