import { afterNextRender, Component } from '@angular/core';
import { Location } from '@angular/common';
import { commonImports } from '../app.imports';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoadingService } from '../services/loading.service';
import {BotAction, BotOrder, BotPairLookup, BotShortInfo, UserData} from '../abstract/users';
import { combineLatest, finalize } from 'rxjs';
import moment from 'moment';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

@Component({
  selector: 'app-recent-action-page',
  standalone: true,
  imports: [...commonImports],
  templateUrl: './recent-action-page.component.html',
  styleUrls: ['./recent-action-page.component.scss']
})
export class RecentActionPageComponent {
  userId: string | null = null;
  totalPages = 0;
  pageSize = 10;
  periodFilter: string = 'payment';
  isActiveTab: number = 0;
  bots?: {[key: string]: BotShortInfo};
  selectedExchangeHistory!: BotPairLookup;
  selectedBotIdHistory!: string;
  exchanges!: BotPairLookup[];
  selectedExchange!: BotPairLookup;
  selectedBotId: any;
  orders: BotOrder[] = [];
  openOrders: BotOrder[] = [];
  currentPage: number = 1;
  botId: string[] = [];

  periodOptions = [
    {
      key: 'From last payment',
      value: 'payment'
    },
    {
      key: '1 month',
      value: 'months'
    },
    {
      key: '1 year',
      value: 'years'
    },
    {
      key: 'For all period',
      value: 'all'
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private loadingService: LoadingService,
    private userService: UserData,
    private location: Location
  ) {
    afterNextRender(() => {
      window.scrollTo(0, 0);
    });
    this.titleService.setTitle('Recent Actions | Burvix Traders');
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userId');
      this.loadData();
    });
  }

  downloadCsv(): void {
    const query = this.userId ?
      this.userService.getUsersOrdersFile(
      this.userId,
      this.selectedBotIdHistory,
      this.selectedExchangeHistory.name == 'All' ? 0 : this.selectedExchangeHistory.exchangeId,
      this.periodFilter === 'payment',
      this.periodFilter === 'payment' || this.periodFilter === 'all' ? '' : moment().startOf('day').add(-1, this.periodFilter as moment.unitOfTime.DurationConstructor).toISOString(),
      ''
    ) : this.userService.getOrdersFile(
      this.selectedBotIdHistory,
      this.selectedExchangeHistory.name == 'All' ? 0 : this.selectedExchangeHistory.exchangeId,
      this.periodFilter === 'payment',
      this.periodFilter === 'payment' || this.periodFilter === 'all' ? '' : moment().startOf('day').add(-1, this.periodFilter as moment.unitOfTime.DurationConstructor).toISOString(),
      ''
    );

    query.subscribe((response: Blob) => {
      const newBlob = new Blob([response], { type: "text/csv" });
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.download = `orders_${moment().format('yyyyMMDDHHmmss')}.csv`;
      link.click();
      window.URL.revokeObjectURL(data);
    });

  }

  loadData(): void {

    this.loadingService.openLoader();
    const info = this.userId
      ? this.userService.getUserShortBots(this.userId, false)
      : this.userService.getShortBots(false);

    combineLatest([info]).pipe(
      finalize(() => this.loadingService.closeLoader())
    ).subscribe(res => {
      this.bots = res[0].reduce((res, b)=>{
        res[b.botId] = b;
        return res;
      }, {} as {[key: string]: BotShortInfo});
      let botId = Object.keys(this.bots);
      botId.sort((a, b) => {
        let botA = this.bots![a];
        let botB = this.bots![b];

        const aAction = BotAction[botA.currentAction as keyof typeof BotAction];
        const bAction = BotAction[botB.currentAction as keyof typeof BotAction];
        if(aAction > bAction)
          return 1;
        else if(aAction < bAction)
          return -1;

        if(botA.exchangeSettingId < botB.exchangeSettingId)
          return 1;
        else if(botA.exchangeSettingId > botB.exchangeSettingId)
          return -1;

        return 0;
      });
      this.botId = botId;
      this.selectedBotIdHistory = res[0][0].botId;
    });
  }

  get selectedBot() {
    return this.bots![this.selectedBotIdHistory];
  }

  historyPeriodFilterChanged(item: string) {
    this.periodFilter = item;
    this.reloadData();
  }

  historyFilterChanged(item: string) {
    this.selectedBotIdHistory = item;
    this.reloadData();
  }

  reloadData(){
    if(this.showHistoryTable){
      this.loadPage(1);
    } else {
      this.loadOpenedOrders();
    }
  }

  loadPage($event: number) {
    this.currentPage = $event;
    this.loadingService.openLoader();
    const skipItems = ($event - 1) * this.pageSize;

    const isWithoutInvoice = this.periodFilter === 'payment';
    const isAllOrders = this.periodFilter === 'all';

    const orders = this.userId
      ? this.userService.getUserOrders(
        this.userId,
        this.pageSize,
        skipItems,
        this.selectedBotIdHistory,
        this.selectedBot.exchangeSettingId,
        isWithoutInvoice,
        isWithoutInvoice || isAllOrders ? '' : moment().startOf('day').add(-1, this.periodFilter as DurationConstructor).toISOString(),
        ''
      )
      : this.userService.getOrders(
      this.pageSize,
      skipItems,
      this.selectedBotIdHistory,
      this.selectedBot.exchangeSettingId,
      isWithoutInvoice,
      isWithoutInvoice || isAllOrders ? '' : moment().startOf('day').add(-1, this.periodFilter as DurationConstructor).toISOString(),
      ''
    );

    combineLatest([orders]).pipe(
      finalize(() => this.loadingService.closeLoader())
    ).subscribe(res => {
      this.orders = res[0].items;
      this.totalPages = Math.ceil(res[0].totalCount / this.pageSize);
    });
  }

  loadOpenedOrders() {
    this.loadingService.openLoader();
    const order = this.userId
      ? this.userService.getUserOpenOrders(this.userId, this.selectedBotIdHistory)
      : this.userService.getOpenOrders(this.selectedBotIdHistory);

    combineLatest([order]).pipe(
      finalize(() => this.loadingService.closeLoader())
    ).subscribe(res => {
      this.openOrders = res[0];
      this.totalPages = Math.ceil(res[0].length / this.pageSize);
    });
  }

  setActiveTab(id: number) {
      this.isActiveTab = id;
      if(!this.showHistoryTable) {
        this.loadOpenedOrders();
      }
  }

  get showHistoryTable(): boolean {
    return this.isActiveTab == 0;
  }

  goBack(): void {
    this.location.back();
  }
}
