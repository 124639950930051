import { Injectable, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class SeoService implements OnDestroy {

  private readonly destroy$ = new Subject<void>();
  private readonly dom: Document;
  private readonly isBrowser: boolean;
  private linkCanonical?: HTMLLinkElement;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.dom = document;

    if (this.isBrowser) {
      this.linkCanonical = this.createCanonicalTag();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createCanonicalTag() {
    const linkCanonical = this.dom.createElement('link');
    linkCanonical.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(linkCanonical);
    linkCanonical.setAttribute('href', this.getCanonicalUrl());
    return linkCanonical;
  }

  trackCanonicalChanges() {
    if (!this.isBrowser) {
      return;
    }

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntil(this.destroy$),
    )
      .subscribe(() => {
        this.linkCanonical!.setAttribute('href', this.getCanonicalUrl());
      });
  }

  private getCanonicalUrl(): string {
    let link = this.dom.location.origin + this.dom.location.pathname;
    link = link.replace('www.', '');
    return link;
  }
}
