import { Routes } from '@angular/router';
import {LandingPageComponent} from "./landing-page/landing-page.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {CommonInfoPageComponent} from "./common-info-page/common-info-page.component";
import {KnowledgeBaseComponent} from "./knowledge-base/knowledge-base.component";
import {GlossaryComponent} from "./glossary/glossary.component";
import {UserAuthGuardService} from "./services/user.auth.guard.service";
import {MainPageComponent} from "./main-page/main-page.component";
import {CallbackComponent} from "./callback/callback.component";
import { LoginComponent } from './login/login.component';
import { RecentActionPageComponent } from './recent-action-page/recent-action-page.component';
import { ReferralProgramComponent } from './referral-program/referral-program.component';
import { PaymentsComponent } from './payments/payments.component';
import { SettingsComponent } from './settings/settings.component';
import {GuestAuthGuardService} from "./services/guest.auth.guard.service";
import {AboutUsComponent} from "./about-us/about-us.component";
import {RegisterComponent} from "./register/register.component";
import {AdminAuthGuardService} from "./services/admin.auth.guard.service";
import {AdminUsersComponent} from "./admin-users/admin-users.component";
import {AdminBotsComponent} from "./admin-bots/admin-bots.component";
import {CreateInvoiceComponent} from "./create-invoice/create-invoice.component";

export const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'glossary', component: GlossaryComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent, canActivate: [GuestAuthGuardService] },
  { path: 'pages/dashboard/callback', component: CallbackComponent },
  { path: 'pages/dashboard', redirectTo: '/', pathMatch: 'full' },

  { path: 'awesome-admin/users', component: AdminUsersComponent, canActivate: [AdminAuthGuardService] },
  { path: 'awesome-admin/:userId/my', component: MainPageComponent, canActivate: [AdminAuthGuardService] },
  { path: 'awesome-admin/:userId/my/referral-program', component: ReferralProgramComponent, canActivate: [AdminAuthGuardService] },
  { path: 'awesome-admin/:userId/my/payments', component: PaymentsComponent, canActivate: [AdminAuthGuardService] },
  { path: 'awesome-admin/:userId/my/recent-action', component: RecentActionPageComponent, canActivate: [AdminAuthGuardService] },
  { path: 'awesome-admin/:userId/my/bots', component: AdminBotsComponent, canActivate: [AdminAuthGuardService] },
  { path: 'awesome-admin/:userId/my/bots/:botId', component: CreateInvoiceComponent, canActivate: [AdminAuthGuardService] },
  { path: 'awesome-admin/:userId/my/:exchange', component: MainPageComponent, canActivate: [AdminAuthGuardService] },

  { path: 'my/recent-action', component: RecentActionPageComponent, canActivate: [UserAuthGuardService] },
  { path: 'my/settings', component: SettingsComponent, canActivate: [UserAuthGuardService] },
  { path: 'my/referral-program', component: ReferralProgramComponent, canActivate: [UserAuthGuardService] },
  { path: 'my/payments', component: PaymentsComponent, canActivate: [UserAuthGuardService] },
  { path: 'my', component: MainPageComponent, canActivate: [UserAuthGuardService] },
  { path: 'my/:exchange', component: MainPageComponent, canActivate: [UserAuthGuardService] },
  { path: 'knowledge-base/:page', component: KnowledgeBaseComponent },
  { path: 'knowledge-base', redirectTo: '/knowledge-base/bot-launch' },
  { path: 'pages/:page', component: CommonInfoPageComponent },
  { path: '**', component: NotFoundComponent },
];
