import { Observable } from 'rxjs'

export interface PackageInfo {
    id: number;
    name: string;
    condition: string;
    description: string;
    isAdvanceInvoice: true;
}

export interface StrategyInfo {
    id: number;
    name: string;
    description: string;
}

export interface CurrencyInfo {
    id: number;
    base: string;
    quote: string;
    minAmount: number;
    exchanges: string[];
}

export abstract class LookupData {
    abstract getPackages(): Observable<PackageInfo[]>;
    abstract getStrategies(): Observable<StrategyInfo[]>;
    abstract getCurrencies(): Observable<{[key: number]: CurrencyInfo[]}>;
    abstract get defaultStrategy(): number;
}
