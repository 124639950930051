<div class="landing-page">
  @if(mainPage){
    @for (block of mainPage.Body; track block) {
      @switch (block.__component){
        @case ('landings.start-block') {
          <div class="openner">
            <div class="main-openner">
              <div class="text-openner">
                <h1>{{block['title']}}</h1>
                <p>{{block['subTitle']}}</p>
                <div class="button-container"><a class="box sign-up new-padding" [routerLink]="[block['startLink']]">{{block['startButton']}}</a></div>
              </div>
            </div>
            <img class="background-img" [ngSrc]="block['backgroundUrl']" alt="bg" width="1921" height="1080" priority>
            <img class="background-img-mob" ngSrc="assets/images/mob-bg-opener.png" alt="bg" width="375" height="1121" priority>
          </div>
        }
        @case ('landings.api-section'){
          <div id="api-keys" class="trade-info-block">
            <div class="trade-info-block-inner">
              <div class="trade-info-text">
                <h2 class="gradient-text">{{block['title']}}</h2>
                <p>{{block['subTitle']}}</p>

                <div class="button-container"><a [routerLink]="[block['aboutUrl']]" class="box sign-up new-padding" >{{block['aboutName']}}</a></div>
              </div>

              <div class="exchange-block">
                @for(item of block['exchanges']['data']; track item; let idx = $index, count = $count){
                  <a [href]="'https://' + item.attributes.link" target="_blank" [class.isCentral]="($index+1)*2===$count"><img [ngSrc]="item.attributes.mainIcon" [class]="item.attributes.slug" [alt]="item.attributes.slug" [width]="getSize(item.attributes.slug).width" [height]="getSize(item.attributes.slug).height"></a>
                }
              </div>
            </div>

            <img class="bg-exchange" ngSrc="/assets/images/api%20keys.svg" alt="20keys" width="1921" height="1301" priority />
          </div>
        }
        @case('landings.payment-block'){
          <div class="payments-info-block">
            <div class="payments-info-block-inner">
              <ngx-text-block [blocks]="convertToTextBlock(block, 'text')"></ngx-text-block>
              <h2 class="gradient-text">{{block['subTitle']}}</h2>
            </div>

            <img ngSrc="/assets/images/grid.png" alt="grid" width="1662" height="819">
          </div>
        }
        @case('landings.api-block') {
          <div class="api-block">
            <div class="api-block-inner">
              <img ngSrc="/assets/images/Subtract.png" class="subtract" alt="subtract" width="796" height="401">
              <img ngSrc="/assets/images/Subtract-mob.png" class="subtract-mob" alt="subtract-mob" width="329" height="378">
              <div class="shadow"></div>
              <p>{{block['description']}}</p>
            </div>
            <div class="api-block-img"><img [ngSrc]="block['iconUrl']" alt="api" width="869" height="745"></div>
          </div>
        }
        @case('landings.prices'){
          <div id="fees-payments" class="payment">
            <div class="payment-inner">
              <h2 class="header">{{block['title']}}</h2>

              <div class="payments-statistic">
                <div class="what-we-do-not">
                  @for(item of block['plusList']; track item; let isLast = $last) {
                    <p><span class="icon"></span>{{item}}</p>
                    @if(!isLast) {
                      <div class="line"></div>
                    }
                  }
                </div>
                <div class="prof-block">
                  <h2 class="gradient-text"><span>{{block['profitPercentage']}}</span> {{block['profitDescription']}}</h2>
                  <div class="prof-block-inner">
                    @for(item of block['profitList']; track item;){
                      <p>{{item}}</p>
                    }
                  </div>
                </div>
              </div>

              <div class="button-container"><a class="box sign-up new-padding" [href]="block['detailsUrl']">About our fee</a></div>
            </div>

            <img class="payment-bg" ngSrc="/assets/images/payment-bg.png" alt="payment-bg" width="1920" height="1403" priority>
            <img class="payment-vector" ngSrc="/assets/images/payment-vector.png" alt="payment-bg" width="1845" height="824" priority>
          </div>
        }
        @case('landings.profitability') {
          <div id="our-strategies" class="profitability">
            <h2 class="header">{{block['title']}}</h2>
            <div class="profitability-inner">
              <div class="profitability-text">
                <h2 class="gradient-text"><span>{{block['percentage']}}</span> {{block['percentageDescription']}}</h2>
                <div class="line"></div>
                <ngx-text-block [blocks]="convertToTextBlock(block, 'description')"></ngx-text-block>
              </div>
              <div class="profitability-graph">
                <img class="frame" ngSrc="/assets/images/blockForgraph.png" alt="blockForgraph" width="763" height="468">
                <img class="chart" [ngSrc]="block['imageUrl']" alt="chart" width="635" height="373">
                <img class="chart-mob" ngSrc="/assets/images/chart-mob.png" alt="chart" width="327" height="415">
                <div class="shadow"></div>
              </div>
            </div>
            <div class="help-dots">
              <a [href]="block['detailsUrl']" class="box-with-dots">{{block['detailsName']}}</a>
            </div>
          </div>
        }
        @case('landings.contact-us') {
          <div id="contact-us" class="contact-us">
            <img class="form" ngSrc="/assets/images/contact-us-form.png" alt="contact-us-form" width="1920" height="388">
            <div class="contact-us-inner">
              <h2 class="gradient-text">{{block['text']}}</h2>
              <div class="button-container"><a class="box sign-up new-padding" [href]="block['contactUrl']" target="_blank">{{block['contactName']}}</a></div>
            </div>
          </div>
        }
        @case('landings.team') {
          <div id="core-team" class="team-block">
            <h2>{{block['title']}}</h2>

            <div class="team-items">
              @for(item of block['teams']['data']; track item; let idx = $index, count = $count){
                <a [href]="item.attributes.link" target="_blank">
                  <div class="team">
                    <div class="img-container"><div class="shadow-team"></div><div class="img-container-inner"><img [ngSrc]="item.attributes.photoUrl" [alt]="item.attributes.name" width="345" height="364"></div></div>
                    <div class="text-container">
                      <h3>{{item.attributes.name}}</h3>
                      <h5 class="gradient-text">{{item.attributes.title}}</h5>
                      <p>{{item.attributes.description}}</p>
                    </div>
                  </div>
                </a>
              }
            </div>
          </div>
        }
        @case('components.carousel') {
          <ngx-carousel [entity]="block['entityName']" [itemMap]="block['itemMap']" [header]="block['title']" [baseLink]="block['baseLink']" [moreLink]="block['moreLink']"></ngx-carousel>
        }
      }
    }
  }


</div>
