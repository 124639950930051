import {Component, OnInit} from '@angular/core';

import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {InfoData, GlossaryItem, GlossaryPage} from '../abstract/info';
import { combineLatest } from 'rxjs';
import {commonImports} from "../app.imports";

@Component({
    selector: 'ngx-glossary',
    standalone: true,
    imports: [...commonImports],
    templateUrl: './glossary.component.html',
    styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent implements OnInit{
    item?: GlossaryItem[];
    page?: GlossaryPage;

    groupedItems: {[key: string]: GlossaryItem[]} = {};
    filteredItems: {[key: string]: GlossaryItem[]} = {};
    searchTerm: string = '';

    constructor(
        private readonly titleService: Title,
        private readonly router: Router,
        private readonly infoService: InfoData
    ) {
        this.titleService.setTitle('Glossary | Burvix Traders');
    }

    ngOnInit(): void{
      this.loadInfoContent();
    }

    loadInfoContent() {
      combineLatest([
        this.infoService.getDataCollection<GlossaryItem[]>('glossaries', [], [], null, null, null),
        this.infoService.getDataCollection<GlossaryPage>('glossary-info', [], [], null, null, null)
      ]).subscribe(([item, page]) => {
        if (!item.data || !page.data) {
          this.router.navigate(['/404']);
          return;
        }

        this.page = page.data;
        this.item = item.data;

        this.groupedItems = this.item.reduce((acc, item) => {
          const letter = item.attributes.letter;
          if (!acc[letter]) {
            acc[letter] = [];
          }
          acc[letter].push(item);
          return acc;
        }, {} as {[key: string]: GlossaryItem[]});
        this.filteredItems = this.groupedItems;
      });

    }

    onSearch() {
      this.filterItems(); // Фильтруем данные после нажатия на кнопку
    }


    scrollToSection(letter: string) {
      const element = document.getElementById(letter);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center'});
      }
    }

    filterItems() {
      const searchLower = this.searchTerm.toLowerCase(); // Преобразуем запрос в нижний регистр

      if (!searchLower) {
        this.filteredItems = this.groupedItems; // Если поисковый запрос пуст, показываем все элементы
        return;
      }

      // Фильтруем элементы
      this.filteredItems = Object.keys(this.groupedItems).reduce((acc, letter) => {
        const filteredGroup = this.groupedItems[letter].filter((item: any) =>
          item.attributes.name.toLowerCase().includes(searchLower) || // Поиск по имени
          item.attributes.body[0]?.children[0]?.text.toLowerCase().includes(searchLower) // Поиск по описанию
        );

        if (filteredGroup.length) {
          acc[letter] = filteredGroup; // Добавляем группу, если она не пуста после фильтрации
        }

        return acc;
      }, {} as {[key: string]: GlossaryItem[]});
    }


}
