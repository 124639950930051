import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { environment } from '../../environments/environment';
import {Filter, InfoData, InfoResponseBase} from '../abstract/info';


@Injectable()
export class InfoDataService extends InfoData {

    constructor(private http: HttpClient) {
        super();
    }

    getDataCollection<T>(entity:string, includes: string[], filters: Filter[], sortFields: string[]|null, limit: number, page: number): Observable<InfoResponseBase<T>> {
      const queryItems = [];

      // Hack for SSR. Somehow server calls /pages/<css-style>.css routes and it requests Strapi for each of this execution. So we skip this requests
      if(entity.endsWith(".css") || entity.endsWith(".css.map")) {
        return of({
          data: null!
        });
      }

      if (page) {
          queryItems.push(`pagination[page]=${page}`);
      }

      if (limit) {
          queryItems.push(`pagination[pageSize]=${limit}`);
      }

      if(sortFields && sortFields.length > 0) {
        for (let i = 0; i < sortFields.length; i++) {
          queryItems.push(`sort[${i}]=${sortFields[i]}`);
        }
      }

      const f = this.generateFilters(filters);
      const i = this.generateIncludeQuery(includes);

      if (f !== '') {
        queryItems.push(f);
      }

      if (i !== '') {
          queryItems.push(i);
      }

      return this.http.get<InfoResponseBase<T>>(`${environment.infoUrl}api/${entity}?${queryItems.join('&')}`, {
        headers: {
          'Strapi-Response-Format': 'v4'
        }
      });
    }

    generateFilters(filters: Filter[]) {
      const res = [];
      for (const filter of filters) {
        res.push(this.generateFilterQuery(filter));
      }

      return res.join('&');
    }

    generateFilterQuery(filter: Filter) {
      return `filters[${filter.field}][${filter.type}]=${filter.value}`;
    }

    generateIncludeQuery(includes: string[]) {
      const res = [];
      for (let i = 0; i < includes.length; i++) {
          if(includes[i].startsWith('[')){
              res.push(`populate${includes[i]}`);
          } else {
              res.push(`populate[${i}]=${includes[i]}`);
          }
      }

      return res.join('&');
    }
}
