import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {commonModuleImports} from "../../app.imports";

@Component({
  imports: [...commonModuleImports],
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  template: `
    <div class="new-footer" [ngStyle]="{'background': backgroundColor}" [ngClass]="{'main': mainFooter}">
      <div class="footer-upper">
        <div class="contact-us-icons">
          <img class="logo-f" ngSrc="/assets/images/logo.svg" alt="Logo" width="137" height="36" />

            <div class="subtext-footer">
              <p>©{{year}} Copyright by Burvix Traders.</p>
              <p>All Rights Reserved.</p>
            </div>

            <div class="icons">
              <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@burvix.io" target="_blank"><img ngSrc="/assets/images/mail.svg" alt="mail" width="30" height="24"></a>
<!--              <a href="#"><img src="/assets/images/youtube.svg" alt="youtube"></a>-->
              <a href="https://t.me/Burvix_support" target="_blank"><img ngSrc="/assets/images/telegram.svg" alt="telegram" width="28" height="28"></a>
              <!--<a href="https://www.facebook.com/manimama.eu" target="_blank"><img ngSrc="/assets/images/facebook.svg" alt="facebook" width="18" height="28"></a>
              <a href="https://www.linkedin.com/company/79972677/" target="_blank"><img ngSrc="/assets/images/linked-in.svg" alt="linked-in" width="30" height="30"></a>-->
            </div>
        </div>

          <div class="company-info">
              <h5>General</h5>
              <div class="links-text">
                  <a [routerLink]="['/about-us']">About Us</a>
                  <a [routerLink]="['/knowledge-base/bot-launch']">Knowledge Base</a>
                  <a [routerLink]="['/knowledge-base/referral-program']">Affiliate</a>
                  <a [routerLink]="['/glossary']">Glossary</a>
              </div>
          </div>

          <div class="company-info">
              <h5>Legal Information</h5>
              <div class="links-text">
                  <a [routerLink]="['/pages/terms-and-conditions']">Terms of service</a>
                  <a [routerLink]="['/pages/privacy-policy']">Privacy policy</a>
                  <a [routerLink]="['/pages/cookie']">Cookie policy</a>
              </div>
          </div>
      </div>
    </div>
  `,
})
export class FooterComponent {
  year: number;
  backgroundColor: string = '#020303';
  mainFooter = false;

  constructor(private readonly router: Router) {
    this.year = (new Date()).getFullYear();

    this.router.events.subscribe(() => {
      this.checkRoute();
    });
  }

  checkRoute() {
    if (this.router.url.indexOf('knowledge-base') !== -1) {
      this.backgroundColor = '#020303';
    } else if (this.router.url.indexOf('/my') !== -1) {
      this.backgroundColor = '#020303';
    } else if (this.router.url.indexOf('/awesome-admin/users') !== -1) {
      this.backgroundColor = '#020303';
    } else {
      this.backgroundColor = '#08101a';
    }

    this.mainFooter = this.router.url.indexOf('/my') !== -1 || this.router.url.indexOf('/awesome-admin/users') !== -1;
  }

}
